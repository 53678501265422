import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

function MainCashBack() {
    return (
        <div className="bg-white p-2 flex flex-col gap-1">
            <div className="flex items-center gap-2">
                <div className="w-10 aspect-square bg-[#f1582c] rounded-full p-2">
                    <SVG
                        src="img/ui/bag.svg"
                        width={'auto'}
                        height={'auto'}
                        fill="white"
                    />
                </div>
                <div className="flex gap-1">
                    <span className="font-bold text-[1.1rem]">
                        MUA SẮM HOÀN TIỀN
                    </span>
                    <div>
                        <span className="px-2 bg-[#f1582c] rounded-lg text-white text-xs">
                            HOT
                        </span>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-2 text-white pt-4">
                <Link
                    to={'shopee'}
                    className="col-span-1 p-1 flex flex-col md:p-2 justify-end rounded-md w-full aspect-square bg-gradient-to-br from-[#fd6b64] to-[#ff8243]"
                >
                    <div className="flex-1 flex flex-col justify-center items-center overflow-hidden h-full aspect-square">
                        <div className="flex h-2/3 aspect-square flex-col items-center justify-center p-1">
                            <SVG
                                src="img/ui/shopee.svg"
                                width={'auto'}
                                height={'auto'}
                                fill="white"
                            />
                        </div>
                        <span className="h-1/3 font-bold md:text-xl">
                            Shopee
                        </span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-[10px] md:text-base">
                            Hoàn đến
                        </span>
                        <div className="flex justify-between items-center">
                            <span className="font-semibold leading-3 md:text-xl">
                                30.28%
                            </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                    </div>
                </Link>
                <Link
                    to={'/tiktok'}
                    className="col-span-1 p-1 flex flex-col md:p-2 justify-end rounded-md w-full aspect-square bg-gradient-to-br from-[#ff3e64] to-[#361f1f]"
                >
                    <div className="flex-1 flex flex-col justify-end items-center overflow-hidden h-full aspect-square">
                        <div className="flex h-2/3 aspect-square flex-col items-center justify-center p-1">
                            <div className="relative aspect-square bg-black rounded-lg flex justify-center items-center h-5/6">
                                <FontAwesomeIcon
                                    icon={faTiktok}
                                    className="text-2xl md:text-[rem]"
                                />
                            </div>
                        </div>
                        <span className="h-1/3 font-bold md:text-xl">
                            TikTok
                        </span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-[10px] md:text-base">
                            Hoàn đến
                        </span>
                        <div className="flex justify-between items-center">
                            <span className="font-semibold leading-3 md:text-xl">
                                20.02%
                            </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                    </div>
                </Link>
                <Link
                    to={'/lazada'}
                    className="col-span-1 p-1 flex flex-col md:p-2 justify-end rounded-md w-full aspect-square bg-gradient-to-br from-[#0dccff] to-[#4760ff]"
                >
                    <div className="flex-1 flex flex-col justify-end items-center overflow-hidden h-full aspect-square">
                        <div className="flex h-2/3 aspect-square flex-col items-center justify-center p-1">
                            <SVG
                                src="img/ui/lazada.svg"
                                width={'auto'}
                                height={'auto'}
                                fill="white"
                            />
                        </div>
                        <span className="h-1/3 font-bold md:text-xl">
                            Lazada
                        </span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-[10px] md:text-base">
                            Hoàn đến
                        </span>
                        <div className="flex justify-between items-center">
                            <span className="font-semibold leading-3 md:text-xl">
                                22.36%
                            </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default MainCashBack;
