import React from 'react';
import TopOfMonth from '../Home/TopOfMonth';
import Header from '../../components/Header';

function TopCashBack() {
    return (
        <div>
            <Header />
            <div className=" p-4 pt-20">
                <TopOfMonth />
            </div>
        </div>
    );
}

export default TopCashBack;
