import React, { useEffect, useState } from 'react';
import authApi from '../../api/authApi';
import formattedMoney from '../../utils/formatMoney';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import rankName from '../../utils/user/rankName';

function TopOfMonth() {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        const fetchUsers = async () => {
            const ONLY_NUM_REGEX = /^\d+$/;
            try {
                const { data } = await authApi.topOfMonth();
                if (data.userList.length < 15) {
                    const fillArray = new Array(10 - data.userList.length).fill(
                        {
                            userId: '-',
                            totalUserCommission: 0,
                        }
                    );
                    setUsers([
                        ...data.userList.filter(
                            (el) =>
                                el.userId &&
                                el.userId !== '' &&
                                ONLY_NUM_REGEX.test(el.userId)
                        ),
                        ...fillArray,
                    ]);
                    return;
                }
                setUsers(
                    data.userList
                        .filter(
                            (el) =>
                                el.userId &&
                                el.userId !== '' &&
                                ONLY_NUM_REGEX.test(el.userId)
                        )
                        .splice(0, 15)
                );
            } catch (error) {
                console.log(error);
            }
        };
        fetchUsers();
    }, []);
    return (
        <div className="bg-gradient-to-tr from-red-300 via-amber-200 to-yellow-400 rounded-lg shadow-lg shadow-orange-400 backdrop-blur-lg">
            <div className="w-full p-6 rounded-lg">
                <label className="font-bold" htmlFor="">
                    <h3>{`Bảng Vàng Tháng ${new Date().getMonth() + 1}`}</h3>
                    <p className="font-normal text-sm">
                        Sự kiện diễn ra: Hàng tháng
                    </p>
                    <p className="font-normal text-sm">
                        Xem thông tin chi tiết{' '}
                        <Link
                            className="font-semibold underline"
                            to={'/post/21'}
                        >
                            TẠI ĐÂY
                        </Link>
                    </p>
                    <p className="font-normal text-xs">
                        (Chỉ ghi nhận các đơn ĐÃ NHẬN HÀNG THÀNH CÔNG)
                    </p>
                    <div className="text-sm py-2 border-b">
                        <h4>Cơ cấu giải thưởng:</h4>
                        <ul className="font-medium">
                            <li>Giải Nhất: 1.000.000đ</li>
                            <li>Giải Nhì: 500.000đ</li>
                            <li>Giải Ba: 200.000đ</li>
                            <li>10 giải tiếp theo mỗi giải 50.000đ</li>
                        </ul>
                    </div>
                </label>
                <table className="w-full text-right">
                    <thead>
                        <tr className="grid grid-cols-4 text-left p-2"></tr>
                    </thead>
                    <tbody>
                        {users.map((el, index) => (
                            <tr key={index} className="grid grid-cols-4">
                                <td className="flex items-center gap-6 col-span-1">
                                    <div
                                        className={`w-10 h-10 relative flex items-center`}
                                    >
                                        <p
                                            className={`${
                                                index === 0 &&
                                                'font-bold text-2xl text-amber-400'
                                            } ${
                                                index === 1 &&
                                                'font-bold text-2xl text-gray-500'
                                            } ${
                                                index === 2 &&
                                                'font-bold text-2xl text-orange-700'
                                            }`}
                                        >
                                            {index + 1}
                                        </p>
                                        {/* {index === 0 && (
                                            <FontAwesomeIcon
                                                icon={faCrown}
                                                className="absolute top-0 right-100 rotate-45 text-amber-400"
                                            />
                                        )} */}
                                    </div>
                                </td>
                                <td className="col-span-2 text-center">
                                    <div className="flex gap-2 items-center justify-start">
                                        <div>
                                            {el.userInfo?.avatar && (
                                                <img
                                                    className="h-8 w-8 object-cover rounded-full"
                                                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/users/${el.userInfo.avatar}`}
                                                    alt="avt"
                                                />
                                            )}
                                            {!el.userInfo?.avatar && (
                                                <div className="w-8 h-8 rounded-full bg-slate-50 flex justify-center items-center text-base">
                                                    <FontAwesomeIcon
                                                        icon={faUser}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="text-left flex flex-col">
                                            <span className="line-clamp-1">
                                                {el.userInfo
                                                    ? el.userInfo.name
                                                        ? el.userInfo.name
                                                        : `user-${el.userId}`
                                                    : '---'}
                                            </span>
                                            <div className="text-xs text-left">
                                                {el.userInfo &&
                                                    rankName(el.userInfo.rank)}
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td className="col-span-1">
                                    {formattedMoney.format(
                                        el.totalUserCommission
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="p-2 text-sm text-center">
                    <p>
                        Kết quả đua top tháng 6{' '}
                        <Link
                            target="_blank"
                            to="https://longhousee.com/post/18"
                            className="font-semibold underline text-primary-600"
                        >
                            https://longhousee.com/post/18
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default TopOfMonth;
