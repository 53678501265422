import React from 'react';
import ProductCardV2 from './ProductCardV2';

function RecommendList({ productList, platform }) {
    return (
        <div className="">
            <div className="flex gap-1 justify-start items-center p-2">
                <div
                    className={`h-10 aspect-square bg-gradient-to-br p-2 from-orange-500
                    to-orange-600 rounded-full`}
                >
                    <img
                        src="img/ui/search.svg"
                        alt="infomation"
                        className="w-full h-full object-cover -scale-x-100"
                    />
                </div>
                <div className="flex flex-col text-sm text-gray-700">
                    <span className="font-bold">SẢN PHẨM LIÊN QUAN</span>
                </div>
            </div>
            <div className="flex flex-wrap px-2">
                {productList.map((el, index) => (
                    <div key={index} className="w-1/2 p-1">
                        <ProductCardV2 productInfo={el} platform={platform} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RecommendList;
