import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import postApi from '../../api/postApi';
import { format } from 'date-fns';
import AnswerQuestion from '../../components/Q&A/Q&A';
import Header from '../../components/Header';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function VideoList() {
    const { userInfo } = useSelector((state) => state.auth);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [removeCatId, setRemoveCatId] = useState();
    const [videoList, setVideoList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [input, setInput] = useState('');
    const fetchVideoList = async (categoryId, searchTerm) => {
        try {
            const { data } = await postApi.getVideoList(
                `categoryId=${categoryId}&productName=${searchTerm}`
            );
            setVideoList(data.videos);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchVideoCategories = async () => {
        try {
            const { data } = await postApi.getVideoCategories();
            setCategories(
                data.videoCategories.map((el) => ({ ...el, isOpen: false }))
            );
        } catch (error) {
            console.log(error);
        }
    };

    const onOpenCateogry = async (categoryId) => {
        fetchVideoList(categoryId, searchTerm);
        setCategoryId(categoryId);
        setCategories((prev) =>
            prev.map((el) => ({
                ...el,
                isOpen: el.categoryId === categoryId,
            }))
        );
    };

    const onDeleteVideoList = async (videoId) => {
        try {
            await postApi.deleteVideoList(videoId);
            await fetchVideoList();
        } catch (error) {
            console.log(error);
        }
    };

    const onSetRemoveCatId = async (categoryId) => {
        setRemoveCatId(categoryId);
        setIsOpenModal(true);
    };

    const onDeleteVideoCate = async () => {
        try {
            await postApi.deleteVideoCategory(removeCatId);
            await fetchVideoCategories();
        } catch (error) {
            console.log(error);
        }
        setIsOpenModal(false);
    };

    useEffect(() => {
        fetchVideoCategories();
    }, []);

    let typingTimer;
    const delayActionTime = 800;
    const handleTypingDone = (e) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            setSearchTerm(e.target.value);
        }, delayActionTime);
    };

    useEffect(() => {
        if (!searchTerm) setInput('');
        if (!categoryId) return;
        fetchVideoList(categoryId, searchTerm);
    }, [searchTerm, categoryId]);

    return (
        <div>
            <Header />
            <div className="p-4 flex flex-col gap-2 pt-16">
                <h1 className="font-bold pb-5 pt-2">
                    CÁC SẢN PHẨM ÁP MÃ ĐỘC QUYỀN LONG HOUSEE
                </h1>
                <div className="">
                    <img
                        src="https://api.longhousee.com/img/post-thumbs/post-1716535988001.jpeg"
                        alt="banner"
                    />
                </div>
                <div className="flex justify-end text-sm italic">
                    <p>{`Cập nhật lần cuối vào ${format(
                        Date.now(),
                        'dd/MM/yyyy'
                    )}`}</p>
                </div>
                <div className="p-2">
                    <span className="font-semibold">
                        ⚠️ Lý do tại sao có list này?
                    </span>
                    <p>
                        ➡️ Mã áp dụng toàn bộ các sản phẩm tại kênh:{' '}
                        <Link
                            to={'https://vn.shp.ee/kr8fcxe?smtt=0.0.9'}
                            target="_blank"
                            className="underline text-primary-500"
                        >
                            https://vn.shp.ee/kr8fcxe?smtt=0.0.9
                        </Link>{' '}
                        (sẽ còn update thêm nhiều sp khác, nhớ bấm follow)
                    </p>
                </div>
                <h4 className="font-bold">Danh sách ngành hàng</h4>
                <div className="flex flex-col gap-1 pb-5">
                    {categories.map((el) => (
                        <div
                            key={el.categoryId}
                            onClick={(e) => {
                                e.stopPropagation();
                                onOpenCateogry(el.categoryId);
                            }}
                            className={`p-2 shadow-md rounded-md bg-white ${
                                el.isOpen && 'shadow-orange-400 border'
                            }`}
                        >
                            <span>
                                🔥 {el.categoryName}{' '}
                                {userInfo && userInfo.role === 'admin' && (
                                    <FontAwesomeIcon
                                        onClick={() =>
                                            onSetRemoveCatId(el.categoryId)
                                        }
                                        className="pl-2 hover:text-orange-600"
                                        icon={faTrash}
                                    />
                                )}
                            </span>
                            {el.isOpen && (
                                <div onClick={(e) => e.stopPropagation()}>
                                    <input
                                        type="text"
                                        placeholder="Tìm kiếm"
                                        value={input}
                                        onChange={(e) =>
                                            setInput(e.target.value)
                                        }
                                        onKeyUp={handleTypingDone}
                                        className="p-1 border rounded-md focus-within:border focus-within:border-primary-400"
                                    />
                                    {videoList.map((el) => (
                                        <div
                                            key={el.id}
                                            className="p-2 odd:bg-slate-100 rounded-md text-sm grid grid-cols-2 text-left"
                                        >
                                            <span className="line-clamp-2">
                                                {el.productName}{' '}
                                                {userInfo &&
                                                    userInfo.role ===
                                                        'admin' && (
                                                        <FontAwesomeIcon
                                                            onClick={() =>
                                                                onDeleteVideoList(
                                                                    el.id
                                                                )
                                                            }
                                                            icon={faTrash}
                                                        />
                                                    )}
                                            </span>
                                            <Link
                                                to={el.url}
                                                className="underline"
                                            >
                                                {el.url}
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <AnswerQuestion postId={17} />
                <div className="pb-10"></div>
            </div>
            <Modal
                title="Xác nhận xoá Danh mục Video?"
                open={isOpenModal}
                onOk={onDeleteVideoCate}
                onCancel={() => setIsOpenModal(false)}
            />
        </div>
    );
}

export default VideoList;
