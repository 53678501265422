import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import convertToK from '../../utils/convertToK';
import formattedMoney from '../../utils/formatMoney';
import Modal from '../Modal';
import RedirectPopup from './RedirectPopup';
import lazadaApi from '../../api/lazadaApi';
import tiktokApi from '../../api/tiktokApi';
import JoinGroup from '../Modal/popup/JoinGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faCopy } from '@fortawesome/free-solid-svg-icons';
import TopToast from '../Toast/TopToast';
import copy from 'copy-to-clipboard';
import linkShopeeAffGenerator from '../../utils/generateLinkAffShopee';

const clickAffLink = (url) => {
    // setTimeout(() => {
    //     window.open(url, '_blank');
    // }, 100);
    window.location.href = url;
};

function ProductCardV2({ productInfo, isLoading, platform, onlyInfo }) {
    const [copied, setCopied] = useState(false);
    const { isLogined, userInfo } = useSelector((state) => state.auth);
    const [link, setLink] = useState('');
    const navigate = useNavigate();
    const {
        productName,
        shopName,
        price,
        commission,
        imageUrl,
        sales,
        productLink,
    } = productInfo;
    const [onGetLink, setOnGetLink] = useState(false);
    const [onCopyLink, setOnCopyLink] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const getLinkAff = async () => {
        const affIdList = [
            {
                sub_id: userInfo.userId,
                aff_id: 17353950011,
            },
        ];
        setOnCopyLink(true);
        if (!isLogined) return navigate(`/login?redirect=${platform}`);
        try {
            if (platform === 'shopee') {
                const linkAff = linkShopeeAffGenerator(productLink, affIdList);
                if (linkAff) {
                    setOnCopyLink(false);
                    setLink(linkAff);
                }
                return;
            }

            if (platform === 'lazada') {
                const { itemId, skuId, offerId } = productInfo;
                try {
                    const { data } = await lazadaApi.getLinkAff(
                        itemId,
                        skuId,
                        offerId
                    );
                    const linkAff = data.linkAff.shortLink;
                    setLink(linkAff);
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
        }
        setOnCopyLink(false);
    };
    let timeOutId;
    const copyLinkToClipboard = (value) => {
        if (!value) return;
        clearTimeout(timeOutId);
        copy(value);
        setCopied(true);
        timeOutId = setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const onCopy = () => {
        if (!link) return getLinkAff();
        copyLinkToClipboard(link);
    };

    const handleSubmit = async () => {
        if (!isLogined) return navigate(`/login?redirect=${platform}`);
        try {
            setOnGetLink(true);
            if (platform === 'shopee') {
                const affIdList = [
                    {
                        sub_id: userInfo.userId,
                        aff_id: 17353950011,
                    },
                ];
                const linkAff = linkShopeeAffGenerator(productLink, affIdList);
                clickAffLink(linkAff);
            }

            if (platform === 'lazada') {
                const { itemId, skuId, offerId } = productInfo;
                try {
                    const { data } = await lazadaApi.getLinkAff(
                        itemId,
                        skuId,
                        offerId
                    );
                    setOnGetLink(false);
                    const linkAff = data.linkAff.shortLink;
                    clickAffLink(linkAff);
                } catch (error) {
                    console.log(error);
                }
            }

            if (platform === 'tiktok') {
                const { itemId } = productInfo;
                try {
                    const { data } = await tiktokApi.addToShowCase(itemId);
                    setOnGetLink(false);
                    const linkAff = `https://tiktok.com/${data.showCase[0].tiktokshopId}`;
                    window.location.href = linkAff;
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
            setOnGetLink(false);
        }
        setOnGetLink(false);
    };

    return (
        <div className="flex flex-col text-xs gap-1 relative w-full bg-white shadow-md rounded-lg border h-full">
            <div className="flex justify-center">
                <div className="w-full aspect-square overflow-hidden rounded-lg border border-black">
                    {isLoading && (
                        <div className="bg-slate-300 animate-pulse h-full w-full"></div>
                    )}
                    {!isLoading && (
                        <img
                            className="h-full w-full object-cover"
                            src={imageUrl}
                            alt="quan-ao"
                        />
                    )}
                </div>
            </div>
            {!isLoading && (
                <div className="flex flex-col gap-1 p-1">
                    <span className="overflow-hidden text-ellipsis line-clamp-3 font-bold">
                        {productName.toUpperCase()}
                    </span>
                    <div className="flex justify-between">
                        <span className="overflow-hidden text-ellipsis line-clamp-1">
                            {shopName}
                        </span>
                        {sales && (
                            <span className="whitespace-nowrap">
                                Lượt bán{' '}
                                <strong>{`${convertToK(sales)}`}</strong>
                            </span>
                        )}
                    </div>
                    <span className="text-sm">
                        {formattedMoney.format(price)}
                    </span>
                    <span className="text-primary-600">{`Hoàn tiền đến: ${formattedMoney.format(
                        commission
                    )}`}</span>
                </div>
            )}
            {isLoading && (
                <div className="flex flex-col gap-1 p-1">
                    <span className="h-8 bg-slate-300 w-full animate-pulse rounded-lg"></span>
                    <div className="flex justify-between">
                        <span className="h-4 bg-slate-300 w-full animate-pulse rounded-lg"></span>
                        <span className="h-4 bg-slate-300 w-3/4 animate-pulse rounded-lg"></span>
                    </div>
                    <span className="h-5 w-3/4 bg-slate-300 animate-pulse rounded-lg"></span>
                    <span className="h-4 w-2/3 bg-slate-300 animate-pulse rounded-lg"></span>
                    <span className="h-4 w-2/3 bg-slate-300 animate-pulse rounded-lg"></span>
                </div>
            )}
            {!isLoading && !onlyInfo && (
                <div className="p-2 flex flex-col gap-1">
                    <button
                        onClick={handleSubmit}
                        className="p-2 bg-primary-600 text-white font-semibold rounded bottom-2 right-2 w-full"
                    >
                        MUA NGAY
                    </button>
                    {/* COPY LINK */}
                    {platform !== 'tiktok' && (
                        <div className="relative rounded overflow-hidden">
                            <TopToast copied={copied} setCopied={setCopied} />
                            <button
                                onClick={onCopy}
                                className="p-2 rounded w-full h-full border bg-gray-500 text-white font-bold"
                            >
                                {onCopyLink ? (
                                    <span>Đang lấy...</span>
                                ) : link ? (
                                    <div className="flex justify-center items-center gap-1">
                                        <span>Sao chép link</span>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </div>
                                ) : (
                                    <span>
                                        THƯỞNG THÊM HOA HỒNG{' '}
                                        <FontAwesomeIcon icon={faCoins} />
                                    </span>
                                )}
                            </button>
                        </div>
                    )}
                </div>
            )}
            <Modal isOpen={onGetLink}>
                <RedirectPopup platform={platform} />
            </Modal>
            <Modal isOpen={openModal}>
                <JoinGroup onClose={() => setOpenModal(false)} />
            </Modal>
        </div>
    );
}

export default ProductCardV2;

export { clickAffLink };
