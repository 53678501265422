import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import SVG from 'react-inlinesvg';
import {
    faChevronRight,
    faMedal,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import rankName from '../../utils/user/rankName';
import { linkgroup } from './JoinGroupV2';
import { Tooltip } from 'antd';

function UserHome() {
    const { isLogined, userInfo } = useSelector((state) => state.auth);
    return (
        <div>
            <div className="bg-gradient-to-br from-[#fad126] to-[#ff544f] to-75% w-full aspect-profile overflow-hidden relative">
                <img
                    src="img/ui/bg_wave.png"
                    alt="bgwave"
                    className=" top-0 w-full h-full object-cover z-10"
                />
                {isLogined && (
                    <div className="absolute z-30 w-full h-full top-0 flex items-center">
                        <div className="relative w-3/12 h-2/3 flex items-center justify-center flex-col gap-1">
                            <Link
                                to={'/user-info'}
                                className="h-4/5  aspect-square rounded-full bg-white border-4 border-gray-500 overflow-hidden"
                            >
                                {userInfo.avatar && (
                                    <img
                                        className="object-cover h-full w-full"
                                        src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/users/${userInfo.avatar}`}
                                        alt="user-avatar"
                                    />
                                )}
                                {!userInfo.avatar && (
                                    <span className="flex justify-center items-center bg-primary-200 w-full h-full">
                                        <FontAwesomeIcon icon={faUser} />
                                    </span>
                                )}
                            </Link>
                            <div className="w-3/4 h-5 md:h-6 relative flex justify-between items-center bg-transparent bg-gradient-to-r  from-transparent from-5% via-amber-200  to-amber-500 rounded-2xl">
                                <div className="relative h-full aspect-square">
                                    <FontAwesomeIcon
                                        icon={faMedal}
                                        className=" text-yellow-300 text-shadow-sm text-2xl md:text-3xl absolute bottom-0"
                                    />
                                </div>
                                <span className="text-[10px] md:text-base whitespace-nowrap">
                                    {rankName(userInfo.rank, true)}
                                </span>
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    className="pr-1 text-xs"
                                />
                            </div>
                        </div>
                        <div className="text-white w-9/12 flex flex-col gap-1 md:gap-4">
                            <div className="flex gap-2 items-center">
                                <span>Tổng số dư</span>
                                <Tooltip title="Tổng số dư là tổng hoàn tiền dự kiến, gồm các đơn hàng đã duyệt và chờ duyệt, nên có thể thay đổi tùy theo trạng thái đơn hàng (số dư có thể giảm do đơn hủy, hoàn hoặc sàn đánh gian lận)">
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        className="text-sm"
                                    />
                                </Tooltip>
                            </div>
                            <div className="flex gap-1 items-end">
                                <span className="text-3xl md:text-5xl font-lexend font-semibold">
                                    {isLogined
                                        ? parseFloat(
                                              userInfo.cash +
                                                  (userInfo.pendingCash || 0)
                                          ).toLocaleString('vi-VN')
                                        : 'Cần LOGIN!'}
                                </span>
                                <span>VNĐ</span>
                            </div>
                            <div className="grid grid-cols-8 w-full gap-1 pr-2">
                                <Link
                                    to={'user-info/withdraw'}
                                    className="col-span-3 bg-[#6585f2] rounded-md overflow-hidden relative"
                                >
                                    <img
                                        className="w-full h-full object-cover translate-y-1"
                                        src="img/ui/cashback_wave.png"
                                        alt="cashback"
                                    />
                                    <div className="absolute w-full h-full top-0  grid grid-cols-8 items-center justify-between px-1">
                                        <div className="p-1 w-full h-full col-span-2 flex justify-center items-center">
                                            <div className="w-full col-span-2 aspect-square rounded-full bg-white p-1 overflow-hidden">
                                                <SVG
                                                    src="img/ui/coin.svg"
                                                    fill="black"
                                                    width={'auto'}
                                                    height={'auto'}
                                                />
                                            </div>
                                        </div>
                                        <span className="text-xs text-center whitespace-nowrap md:text-base col-span-5">
                                            Hoàn tiền
                                        </span>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className="text-xs col-span-1"
                                        />
                                    </div>
                                </Link>
                                <Link
                                    to={'/user-info/order'}
                                    className="col-span-3 bg-[#b790fd] rounded-md overflow-hidden relative"
                                >
                                    <img
                                        className="w-full h-full object-cover translate-y-1"
                                        src="img/ui/order_wave.png"
                                        alt="order"
                                    />
                                    <div className="absolute w-full h-full top-0  grid grid-cols-8 items-center justify-between px-1">
                                        <div className="p-1 w-full h-full col-span-2 flex justify-center items-center">
                                            <div className="w-full col-span-2 aspect-square rounded-full bg-white p-1 overflow-hidden">
                                                <SVG
                                                    src="img/ui/cart.svg"
                                                    fill="black"
                                                    width={'auto'}
                                                    height={'auto'}
                                                />
                                            </div>
                                        </div>
                                        <span className="text-xs text-center whitespace-nowrap md:text-base col-span-5">
                                            Đơn hàng
                                        </span>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className="text-xs col-span-1"
                                        />
                                    </div>
                                </Link>
                                <Link
                                    to={linkgroup.zaloLonghousee}
                                    target="_blank"
                                    className="col-span-2 flex justify-center items-center gap-1 p-1 py-2 rounded-md text-sm bg-gray-500"
                                >
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faQuestionCircle}
                                        />
                                    </span>
                                    <span className="text-xs md:text-base">
                                        Trợ giúp
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                {!isLogined && (
                    <div className="absolute z-30 w-full h-full top-0 flex justify-center items-center font-lexend text-xl text-center">
                        <div className="h-full p-4">
                            <img
                                src="img/ui/longiconhi.svg"
                                alt="longicon"
                                className="h-full object-cover"
                            />
                        </div>
                        <div className="p-2">
                            <div>
                                <span className="font-bold text-white">
                                    SĂN SALE CỰC ĐỈNH
                                </span>
                                <p className="text-white font-semibold">
                                    HOÀN TIỀN CỰC ĐÃ
                                </p>
                            </div>
                            <div>
                                <Link
                                    to={'/login'}
                                    className="px-4 py-2 text-base bg-slate-600 text-white rounded-lg mt-2"
                                >
                                    ĐĂNG NHẬP
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserHome;
