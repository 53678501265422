import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SVG from 'react-inlinesvg';

import 'swiper/css';
import 'swiper/css/bundle';
import { Link } from 'react-router-dom';
import postApi from '../../api/postApi';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function PostSlider() {
    const [posts, setPost] = useState([]);

    const fetchPost = async () => {
        try {
            const { data } = await postApi.getGuidePost(
                'category=guide&status=2&sort=-createTime'
            );
            setPost(data.post);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchPost();
    }, []);
    return (
        <div className="p-2 flex flex-col gap-3">
            <Link to={'/post'} className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                    <div className="w-10 aspect-square bg-[#f1582c] rounded-full p-2">
                        <SVG
                            src="img/ui/blog.svg"
                            width={'auto'}
                            height={'auto'}
                            fill="white"
                        />
                    </div>
                    <div className="flex gap-1">
                        <span className="font-bold text-[1.1rem]">
                            MẸO SĂN SALE
                        </span>
                        <div>
                            <span className="px-2 bg-[#f1582c] rounded-lg text-white text-xs">
                                BLOG
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-1">
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            </Link>
            <div>
                <Swiper
                    modules={[Autoplay]}
                    className="shadow-md rounded-lg overflow-hidden h-full"
                    slidesPerView={1.3}
                    spaceBetween={4}
                    autoplay
                >
                    {posts.map((el, index) => (
                        <SwiperSlide
                            key={index}
                            className="shadow-lg rounded-2xl overflow-hidden bg-white h-full"
                        >
                            <Link
                                to={`/post/${el.postId}`}
                                className="flex flex-col justify-between gap-1 h-full"
                            >
                                <div className="">
                                    <img
                                        crossOrigin="anonymous"
                                        className="object-cover h-24 w-full"
                                        src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/post-thumbs/${el.imagePath}`}
                                        alt={el.title}
                                    />
                                </div>
                                <div className="px-2">
                                    <span className="font-semibold line-clamp-1 text-sm">
                                        {el.title.toUpperCase()}
                                    </span>
                                    <p className="line-clamp-2 text-sm">
                                        {el.summary}
                                    </p>
                                </div>
                                <div className="flex justify-end p-2">
                                    <button className="p-1 bg-[#f1582c] text-white rounded-3xl text-sm px-2">
                                        XEM THÊM
                                    </button>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default PostSlider;
